import { Box, Typography, useTheme } from "@mui/material";

import NavBar from "../../navigation/NavBar";

const AntiMalwareDash = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        minHeight: "110vh",
      }}
    >
      <NavBar link="/AntiMalwareDash" />
      <Box>
        <Typography>AntiMalware Dash</Typography>
      </Box>
    </Box>
  );
};

export default AntiMalwareDash;
